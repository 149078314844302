import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./DBFetcher.css";
import ExportExcel from "./ExportExcel";
import * as XLSX from "xlsx";
import { use } from "bcrypt/promises";

const DBFetcher = ({ user }) => {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [filteredRFIDs, setFilteredRFIDs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKondisi, setFilterKondisi] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(true); // true for update, false for add
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [deleteReason, setDeleteReason] = useState("");
  const location = useLocation();
  const [gedungOptions, setGedungOptions] = useState([]);
  const [lantaiOptions, setLantaiOptions] = useState([]);
  const [ruanganOptions, setRuanganOptions] = useState([]);
  const [selectedGedung, setSelectedGedung] = useState("");
  const [selectedLantai, setSelectedLantai] = useState("");
  const [selectedRuangan, setSelectedRuangan] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [rfidOptions, setRfidOptions] = useState([]);
  const [newAsset, setNewAsset] = useState({
    no_rfid: "",
    no_inventaris: "",
    kode_asset: "",
    nama_asset: "",
    kondisi: "",
    umur_pakai: "",
    tanggal_perawatan: "",
    nama_pic: "",
    status_asset: "",
    harga_perolehan: "",
    no_contract: "",
    keterangan: "",
    gedung: "",
    lantai: "",
    ruangan: "",
  });

  useEffect(() => {
    fetchAvailableRfids();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchTerm, filterKondisi, assets]);

  useEffect(() => {
    handleSortAssets();
  }, [sortConfig]);

  useEffect(() => {
    // Fetch all Gedung on component load
    fetchGedungOptions();
  }, []);

  useEffect(() => {
    fetchAssets();
  }, []);
  useEffect(() => {
    // Fetch all Gedung on component load
    fetchScannerData();
  }, []);

  const [scannerData, setScannerData] = useState([]);
  const fetchScannerData = async () => {
    try {
      const response = await fetch(
        `https://amipartnership.com/api/data/${user.user_id}`
      );
      const data = await response.json();
      const rfidArray = data.data.split(",").map((rfid) => rfid.trim()); // Trim whitespace
      setScannerData(rfidArray);
      console.log("Scanner data fetched:", rfidArray);
      compareAssetsWithScanner(rfidArray); // Compare assets with scanner data
    } catch (error) {
      console.error("Error fetching RFID options:", error);
    }
  };

  const fetchAssets = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/assets");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAssets(data.assets);
      setFilteredAssets(data.assets);
      console.log("Data fetched:", data.assets);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };
  const [missingAssets, setMissingAssets] = useState([]);
  const compareAssetsWithScanner = (rfidArray) => {
    const uniqueRfidArray = [...new Set(rfidArray)]; // Unique RFID
    console.log("Unique RFID Array:", uniqueRfidArray); // Debugging line

    const missing = assets.filter((asset) => {
      const noRfidTrimmed = asset.no_rfid.trim(); // Trim whitespace
      const isMissing = !uniqueRfidArray.includes(noRfidTrimmed);
      return isMissing; // Return if it's missing
    });

    console.log("Missing Assets:", missing); // Log missing assets
    setMissingAssets(missing); // Update state with missing assets
  };

  useEffect(() => {
    console.log("Missing assets:", missingAssets);
  }, [missingAssets]);

  const exportToCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      missingAssets
        .map(
          (asset) =>
            `${asset.no_rfid},${asset.no_inventaris},${asset.kode_asset},${asset.nama_asset},${asset.kondisi}`
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "missing_assets.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };
  console.log("Missing assets:", missingAssets);

  const fetchAvailableRfids = async () => {
    try {
      const response = await fetch(
        "https://amipartnership.com/api/available-rfids"
      );
      const data = await response.json();
      setRfidOptions(data);
    } catch (error) {
      console.error("Error fetching RFID options:", error);
    }
  };

  const fetchGedungOptions = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/gedung");
      const data = await response.json();
      setGedungOptions(data);
    } catch (error) {
      console.error("Error fetching gedung options:", error);
    }
  };

  const fetchLantaiOptions = async (gedung) => {
    try {
      const response = await fetch(
        `https://amipartnership.com/api/lantai?gedung=${gedung}`
      );
      const data = await response.json();
      setLantaiOptions(data);
    } catch (error) {
      console.error("Error fetching lantai options:", error);
    }
  };

  const fetchRuanganOptions = async (gedung, lantai) => {
    try {
      const response = await fetch(
        `https://amipartnership.com/api/ruangan?gedung=${gedung}&lantai=${lantai}`
      );
      const data = await response.json();
      setRuanganOptions(data);
    } catch (error) {
      console.error("Error fetching ruangan options:", error);
    }
  };

  const getLocationId = async () => {
    // Validate that all selections are made
    if (!selectedGedung || !selectedLantai || !selectedRuangan) {
      alert("Please select Gedung, Lantai, and Ruangan.");
      return null;
    }

    try {
      // Make the POST request with the selected values in the request body
      const response = await fetch("https://amipartnership.com/api/location", {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json", // Specify content type
        },
        body: JSON.stringify({
          gedung: selectedGedung,
          lantai: selectedLantai,
          ruangan: selectedRuangan,
        }), // Send the body as JSON
      });

      // Check if the response is successful
      if (!response.ok) {
        const contentType = response.headers.get("content-type");

        // Handle case where response is in HTML format
        if (contentType && contentType.includes("text/html")) {
          const text = await response.text(); // Get the HTML response
          throw new Error(`HTML error: ${text}`);
        }

        // Handle JSON error message from the server
        const errorData = await response.json();
        throw new Error(
          errorData.message || `Error fetching location: ${response.statusText}`
        );
      }

      // Parse the JSON response
      const data = await response.json();

      if (data.ID_Lokasi) {
        console.log("Location ID:", data.ID_Lokasi);
        return data.ID_Lokasi; // Return the location ID from the response
      } else {
        throw new Error("Invalid response: Location ID not found.");
      }
    } catch (error) {
      console.error("Error fetching location:", error.message);
      alert(`Failed to fetch location ID: ${error.message}`);
      return null; // Return null on error
    }
  };

  const handleGedungChange = (event) => {
    const selectedGedung = event.target.value;
    setSelectedGedung(selectedGedung);
    setSelectedLantai(""); // Reset Lantai
    setSelectedRuangan(""); // Reset Ruangan
    fetchLantaiOptions(selectedGedung); // Fetch Lantai options
    handleChange(event); // Pass selected value to parent
  };

  const handleLantaiChange = (event) => {
    const selectedLantai = event.target.value;
    setSelectedLantai(selectedLantai);
    setSelectedRuangan(""); // Reset Ruangan
    fetchRuanganOptions(selectedGedung, selectedLantai); // Fetch Ruangan options
    handleChange(event); // Pass selected value to parent
  };

  const handleRuanganChange = (event) => {
    const selectedRuangan = event.target.value;
    setSelectedRuangan(selectedRuangan);
    handleChange(event); // Pass selected value to parent
  };

  const handleRefresh = () => {
    fetchAssets();
    fetchScannerData();
  };

  const handleDownloadExcel = () => {
    // Implement download Excel functionality
  };

  const handleDownloadPDF = () => {
    // Implement download PDF functionality
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterKondisi = (event) => {
    setFilterKondisi(event.target.value);
  };

  const handleFilter = () => {
    let filteredData = assets;

    if (searchTerm) {
      filteredData = filteredData.filter((asset) =>
        Object.values(asset).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (filterKondisi) {
      filteredData = filteredData.filter(
        (asset) => asset.kondisi === filterKondisi
      );
    }

    setFilteredAssets(filteredData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Pass key and direction to handleSortAssets
    handleSortAssets(key, direction);

    // Update the sortConfig state after sorting
    setSortConfig({ key, direction });
  };

  const handleSortAssets = (key, direction) => {
    let sortedAssets = [...filteredAssets];

    sortedAssets.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredAssets(sortedAssets);
  };

  const handleRowClick = (asset) => {
    setSelectedAsset(asset);
    setIsUpdateMode(true);
    setshowUpdateModal(true);
    setSelectedGedung(asset.gedung);
    setSelectedLantai(asset.lantai);
    setSelectedRuangan(asset.ruangan);
  };

  const handleAddButtonClick = () => {
    setIsUpdateMode(false);
    setshowUpdateModal(true);
  };

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  };

  const closeUpdateModal = () => {
    setshowUpdateModal(false);
    setSelectedAsset(null);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setDeleteReason(null);
  };

  // Format the date to yyyy-MM-dd
  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const handleFormSubmitUpdate = async (event) => {
    event.preventDefault();

    try {
      // Wait for the location ID to be fetched
      const fetchedLocationId = await getLocationId();

      if (!fetchedLocationId) {
        alert("Failed to fetch location ID, form submission aborted.");
        return; // Abort form submission if location ID is not fetched
      }

      console.log("Selected asset:", selectedAsset);
      console.log("Date assets:", formatDate1(selectedAsset.tanggal_perawatan));
      console.log("User:", user);
      console.log("ID Lokasi:", fetchedLocationId);

      const action = isUpdateMode ? 1 : 0; // 1 for update, 0 for add

      if (isUpdateMode) {
        // Check for changes before updating
        const originalAsset = assets.find(
          (asset) => asset.id_asset === selectedAsset.id_asset
        );

        const changesDetected = Object.keys(selectedAsset).some(
          (key) => selectedAsset[key] !== originalAsset[key]
        );

        if (!changesDetected) {
          alert("No changes detected");
          closeUpdateModal(); // Close modal since no changes were detected
          return;
        }
      }

      // Prepare the body of the request
      const requestBody = {
        ID_Asset: action === 0 ? 0 : selectedAsset.id_asset, // Set to null for auto-increment
        No_RFID: selectedAsset.no_rfid,
        No_Inventaris: selectedAsset.no_inventaris,
        Kode_Asset: selectedAsset.kode_asset,
        Nama_Asset: selectedAsset.nama_asset,
        ID_Lokasi: fetchedLocationId,
        Kondisi: selectedAsset.kondisi,
        Umur_Pakai: formatDate1(selectedAsset.umur_pakai),
        Tanggal_Perawatan: formatDate1(selectedAsset.tanggal_perawatan),
        Nama_PIC: selectedAsset.nama_pic,
        Detail_PIC: selectedAsset.detail_pic,
        Status_Asset: selectedAsset.status_asset,
        Harga_perolehan: selectedAsset.harga_perolehan,
        No_Contract: selectedAsset.no_contract,
        Keterangan: selectedAsset.keterangan,
        Dokumen_ref: selectedAsset.dokumen_ref,
        ID_User: user.user_id,
        Approval_action: action,
        Approval_deletion_reason: "",
      };

      console.log(requestBody);

      const response = await fetch("https://amipartnership.com/api/approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        // Try to parse the error response as JSON
        let errorData;
        try {
          errorData = await response.json();
        } catch (jsonError) {
          errorData = { message: "Unknown error" }; // Fallback error message
        }

        // If the errorData has a message, include it in the error output
        throw new Error(
          `HTTP error! Status: ${response.status} - ${
            errorData.message || "Unknown error"
          }`
        );
      }

      const data = await response.json();
      console.log("Approval record created:", data);

      alert(
        isUpdateMode
          ? "Asset updated and approval record created successfully!"
          : "Asset added and approval record created successfully!"
      );
      fetchAssets(); // Refresh the asset list after the update

      // Close the modal only when the operation is successful
      closeUpdateModal();
    } catch (error) {
      console.error(
        `Error ${
          isUpdateMode ? "updating" : "adding"
        } asset and creating approval record:`,
        error
      );

      // Displaying more helpful error messages to the user
      if (error.message.includes("NetworkError")) {
        alert(
          "Network error! Please check your internet connection and try again."
        );
      } else if (error.message.includes("HTTP error")) {
        alert(`Server error! ${error.message}`);
      } else {
        alert("An unknown error occurred. Please try again.");
      }
    }
  };

  const handleFormSubmitDelete = async (event) => {
    event.preventDefault();

    if (!deleteReason) {
      alert("Please select a reason for deletion.");
      return;
    }

    console.log("Selected asset:", selectedAsset);
    console.log("Selected reason:", deleteReason);

    const action = 2;

    try {
      const response = await fetch("https://amipartnership.com/api/approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ID_Asset: selectedAsset.id_asset,
          No_RFID: selectedAsset.no_rfid,
          No_Inventaris: selectedAsset.no_inventaris,
          Kode_Asset: selectedAsset.kode_asset,
          Nama_Asset: selectedAsset.nama_asset,
          ID_Lokasi: selectedAsset.id_lokasi,
          Kondisi: selectedAsset.kondisi,
          Umur_Pakai: formatDate1(selectedAsset.umur_pakai),
          Tanggal_Perawatan: formatDate1(selectedAsset.tanggal_perawatan),
          Nama_PIC: selectedAsset.nama_pic,
          Detail_PIC: selectedAsset.detail_pic,
          Status_Asset: selectedAsset.status_asset,
          Harga_perolehan: selectedAsset.harga_perolehan,
          No_Contract: selectedAsset.no_contract,
          Keterangan: selectedAsset.keterangan,
          Dokumen_ref: selectedAsset.dokumen_ref,
          ID_User: user.user_id,
          Approval_action: action,
          Approval_deletion_reason: deleteReason,
        }),
      });

      if (!response.ok) {
        let errorMessage = `HTTP error! Status: ${response.status}`;

        // Handle specific HTTP errors
        switch (response.status) {
          case 400:
            errorMessage = "Bad Request: Please check the input data.";
            break;
          case 409:
            errorMessage = "Conflict: The asset or reference already exists.";
            break;
          case 500:
            errorMessage = "Server error: Please try again later.";
            break;
          default:
            errorMessage = `Unexpected error: ${response.statusText}`;
        }
        throw new Error(errorMessage);
      }

      // Attempt to parse the JSON response
      const data = await response.json();
      console.log("Approval record created:", data);
      alert("Asset approval record created successfully.");
      fetchAssets(); // Refresh the asset list after the update
    } catch (error) {
      console.error(
        "Error deleting asset and creating approval record:",
        error
      );

      // Provide user-friendly feedback based on error type
      if (error.name === "TypeError") {
        alert(
          "Network error: Unable to reach the server. Please check your connection."
        );
      } else {
        alert(`Error: ${error.message}`);
      }
    } finally {
      closeConfirmationModal();
      closeUpdateModal();
    }
  };

  const handleChange = (event) => {
    setSelectedAsset({
      ...selectedAsset,
      [event.target.name]: event.target.value,
    });
  };
  const handleReasonChange = (event) => {
    setDeleteReason(event.target.value); // Update state with selected reason
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  // Function to handle the Excel file upload and parsing
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const [fetchData, setFetchData] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Read the CSV file and update preview state
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      setCsvPreview(rows);
    };
    reader.readAsText(file);
  };
  const showModal = (modalType) => {
    if (modalType === "add") {
      setShowAddModal(true);
    } else if (modalType === "edit") {
      setShowEditModal(true);
    } else if (modalType === "upload") {
      setShowUploadModal(true);
    }
  };
  const closeModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setShowUploadModal(false);
    setFilteredRFIDs([]);
  };
  const handleClearFile = () => {
    setSelectedFile(null);
    setCsvPreview([]);
    fileInputRef.current.value = "";
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile); // Ensure the 'name' matches the server's expected field
    formData.append("ID_User", user.user_id); // Correctly append user_id to formData

    try {
      // Update the URL and endpoint to match the new API route
      const response = await fetch(
        "https://amipartnership.com/api/upload/csv",
        {
          method: "POST",
          body: formData, // Now formData includes both the file and user_id
        }
      );

      if (response.ok) {
        console.log("File uploaded and processed successfully");
        setFetchData(true); // Trigger fetching data
        alert("File uploaded and processed successfully");
        closeModal();
      } else {
        console.error("Failed to upload and process file");
      }
    } catch (error) {
      console.error("Error uploading and processing file:", error);
    }
  };

  const renderCsvPreview = () => {
    if (csvPreview.length === 0) return null;

    // Extract headers and data rows
    const headers = csvPreview[0];
    const dataRows = csvPreview.slice(1);
    const previewRows = dataRows.slice(0, 5);
    const totalRows = dataRows.length;

    return (
      <div className="csv-preview">
        <h3>CSV Preview:</h3>
        <p>Total rows: {totalRows}</p>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{ paddingRight: "24px", textWrap: "nowrap" }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {previewRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{ paddingRight: "24px", textWrap: "nowrap" }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  function calculateUmurEkonomis(umur_pakai){
    const currentDate = new Date();
    const umurPakaiDate = new Date(umur_pakai);
    const diffTime = Math.abs(currentDate - umurPakaiDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return (
    <div className="table-container">
      <div className="search-filter-container">
        {location.pathname !== "/scan-room" && (
          <div>
            <input
              type="text"
              placeholder="Search across all fields..."
              value={searchTerm}
              onChange={handleSearch}
              style={{ float: "right", width: "20%", display: "flex" }}
            />
            <select
              value={filterKondisi}
              onChange={handleFilterKondisi}
              style={{ width: "10%" }}
            >
              <option value="">All Kondisi</option>
              <option value="Baik">Baik</option>
              <option value="Rusak">Rusak</option>
              <option value="Hilang">Hilang</option>
            </select>
            <button className="refresh-button" onClick={handleRefresh}>
              Refresh <i className="bx bx-refresh"></i>
            </button>
            <i className="bx bx-plus" onClick={handleAddButtonClick}></i>
            <i
              className="bx bx-cloud-upload"
              onClick={() => showModal("upload")}
              style={{ marginLeft: "20px" }}
            ></i>
            <ExportExcel />
          </div>
        )}
        {location.pathname === "/scan-room" && (
          <div style={{ textAlign: "left" }}>
            <button
              className="modal-button edit"
              onClick={handleDownloadExcel}
              style={{ maxWidth: "120px" }}
            >
              Export Excel Dokumen Hilang
            </button>
            <button
              className="modal-button edit"
              onClick={handleDownloadPDF}
              style={{ maxWidth: "120px" }}
            >
              Export PDF Dokumen Hilang
            </button>
          </div>
        )}
      </div>

      <table className="asset-table">
        <thead>
          <tr id="tb-header"style={{ backgroundColor: "#abcaf5" }}>
            <th rowSpan="2" onClick={() => handleSort("no_rfid")}>
              No RFID{" "}
              {sortConfig.key === "no_rfid" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("kode_asset")}>
              Kode Asset{" "}
              {sortConfig.key === "kode_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("nama_asset")}>
              Nama Asset{" "}
              {sortConfig.key === "nama_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("no_inventaris")}>
              No Inventaris{" "}
              {sortConfig.key === "no_inventaris" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="3" onClick={() => handleSort("dokumen_ref")}>
              No Dokumen Referensi{" "}
              {sortConfig.key === "dokumen_ref" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("no_contract")}>
              No Contract{" "}
              {sortConfig.key === "no_contract" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("kondisi")}>
              Kondisi{" "}
              {sortConfig.key === "kondisi" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("tanggal_perawatan")}>
              Tanggal Perawatan{" "}
              {sortConfig.key === "tanggal_perawatan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="3" onClick={() => handleSort("harga_perolehan")}>
              Harga Perolehan{" "}
              {sortConfig.key === "harga_perolehan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("nama_pic")}>
              Nama PIC{" "}
              {sortConfig.key === "nama_pic" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("detail_pic")}>
              Detail PIC{" "}
              {sortConfig.key === "detail_pic" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th colSpan="2">Tanggal</th>
            <th rowSpan="2" onClick={() => handleSort("umur_ekonomis")}>
              Umur Ekonomis (Hari){" "}
              {sortConfig.key === "umur_ekonomis" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("status_asset")}>
              Nama Sekolah{" "}
              {sortConfig.key === "status_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th colSpan="3">Lokasi</th>
            <th rowSpan="2" onClick={() => handleSort("keterangan")}>
              Keterangan Asset{" "}
              {sortConfig.key === "keterangan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>  
          </tr>
          <tr id="tb-header" style={{ backgroundColor: "#abcaf5" }}>
            <th onClick={() => handleSort("tanggal_input")}>
              Input{" "}
              {sortConfig.key === "tanggal_input" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("umur_pakai")}>
              Berakhir{" "}
              {sortConfig.key === "umur_pakai" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("gedung")}>
              Gedung{" "}
              {sortConfig.key === "gedung" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("lantai")}>
              Lantai{" "}
              {sortConfig.key === "lantai" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("ruangan")}>
              Ruangan{" "}
              {sortConfig.key === "ruangan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.map((asset, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(asset)}
              style={{
                // background: "var(--tb-body)",
                color: missingAssets.some(
                  (missing) => missing.no_rfid.trim() === asset.no_rfid.trim()
                )
                  ? "#d32f2f"
                  : "",
              }}
            >
              <td>{asset.no_rfid}</td>
              <td>{asset.kode_asset}</td>
              <td>{asset.nama_asset}</td>
              <td>{asset.no_inventaris}</td>
              <td>{asset.dokumen_ref}</td>
              <td>{asset.no_contract}</td>
              <td>{asset.kondisi}</td>
              <td>{formatDate2(asset.tanggal_perawatan)}</td>
              <td>{asset.harga_perolehan}</td>
              <td>{asset.nama_pic}</td>
              <td>{asset.detail_pic}</td>
              <td>{formatDate2(asset.tanggal_input)}</td>
              <td>{formatDate2(asset.umur_pakai)}</td>
              <td>{calculateUmurEkonomis(asset.umur_pakai)}</td>
              <td>{asset.status_asset}</td>
              <td>{asset.gedung}</td>
              <td>{asset.lantai}</td>
              <td>{asset.ruangan}</td>
              <td>{asset.keterangan}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={`modal ${showUpdateModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>{isUpdateMode ? "Update Asset" : "Add New Asset"}</h2>
            <span className="close" onClick={closeUpdateModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form
              id="assetForm"
              onSubmit={
                isUpdateMode ? handleFormSubmitUpdate : handleFormSubmitUpdate
              }
            >
              <label htmlFor="no_rfid">No RFID:</label>
              <input
                type="text"
                id="no_rfid"
                name="no_rfid"
                value={selectedAsset?.no_rfid || ""}
                onChange={handleChange}
                autoComplete="off"
                list="rfidOptions" // Menghubungkan input dengan datalist
                required
                disabled={isUpdateMode}
              />

              <datalist id="rfidOptions">
                {rfidOptions.map((rfid, index) => (
                  <option key={index} value={rfid.rfid_number}>
                    {rfid.rfid_number}
                  </option>
                ))}
              </datalist>
              <label htmlFor="no_inventaris">No Inventaris:</label>
              <input
                type="text"
                id="no_inventaris"
                name="no_inventaris"
                value={selectedAsset?.no_inventaris || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="kode_asset">Kode Asset:</label>
              <input
                type="text"
                id="kode_asset"
                name="kode_asset"
                value={selectedAsset?.kode_asset || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="nama_asset">Nama Asset:</label>
              <input
                type="text"
                id="nama_asset"
                name="nama_asset"
                value={selectedAsset?.nama_asset || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="kondisi">Kondisi:</label>
              <select
                id="kondisi"
                name="kondisi"
                value={selectedAsset?.kondisi || ""}
                onChange={handleChange}
                required
              >
                <option value="">Pilih Kondisi</option>{" "}
                {/* Default placeholder option */}
                <option value="Baik">Baik</option>
                <option value="Rusak">Rusak</option>
                <option value="Sedang diperbaiki">Sedang diperbaiki</option>
              </select>
              <label htmlFor="umur_pakai">Umur Pakai:</label>
              <input
                type="date"
                id="umur_pakai"
                name="umur_pakai"
                value={
                  selectedAsset?.umur_pakai
                    ? selectedAsset.umur_pakai.split("T")[0]
                    : ""
                }
                onChange={handleChange}
              />
              <label htmlFor="tanggal_perawatan">Tanggal Perawatan:</label>
              <input
                type="date"
                id="tanggal_perawatan"
                name="tanggal_perawatan"
                value={
                  selectedAsset?.tanggal_perawatan
                    ? selectedAsset.tanggal_perawatan.split("T")[0]
                    : ""
                }
                onChange={handleChange}
              />
              <label htmlFor="nama_pic">Nama PIC:</label>
              <input
                type="text"
                id="nama_pic"
                name="nama_pic"
                value={selectedAsset?.nama_pic || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="detail_pic">Detail PIC:</label>
              <input
                type="text"
                id="detail_pic"
                name="detail_pic"
                value={selectedAsset?.detail_pic || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="status_asset">Status Asset:</label>
              <input
                type="text"
                id="status_asset"
                name="status_asset"
                value={selectedAsset?.status_asset || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="harga_perolehan">Harga Perolehan:</label>
              <input
                type="text"
                id="harga_perolehan"
                name="harga_perolehan"
                value={selectedAsset?.harga_perolehan || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="no_contract">No Contract:</label>
              <input
                type="text"
                id="no_contract"
                name="no_contract"
                value={selectedAsset?.no_contract || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="keterangan">Keterangan Asset:</label>
              <input
                type="text"
                id="keterangan"
                name="keterangan"
                value={selectedAsset?.keterangan || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="dokumen_ref">Nomor Dokumen Referensi:</label>
              <input
                type="text"
                id="dokumen_ref"
                name="dokumen_ref"
                value={selectedAsset?.dokumen_ref || ""}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <label htmlFor="gedung">Gedung:</label>
              <select
                id="gedung"
                name="gedung"
                value={selectedGedung}
                onChange={handleGedungChange}
                required
              >
                <option value="">Pilih Gedung</option>
                {gedungOptions.map((gedung, index) => (
                  <option key={index} value={gedung.Gedung}>
                    {gedung.Gedung}
                  </option>
                ))}
              </select>

              <label htmlFor="lantai">Lantai:</label>
              <select
                id="lantai"
                name="lantai"
                value={selectedLantai}
                onChange={handleLantaiChange}
                disabled={!selectedAsset?.gedung}
                required
              >
                <option value="">Pilih Lantai</option>
                {lantaiOptions.map((lantai, index) => (
                  <option key={index} value={lantai.Lantai}>
                    {lantai.Lantai}
                  </option>
                ))}
              </select>

              <label htmlFor="ruangan">Ruangan:</label>
              <select
                id="ruangan"
                name="ruangan"
                value={selectedRuangan}
                onChange={handleRuanganChange}
                disabled={!selectedAsset?.gedung || !selectedAsset?.lantai}
                required
              >
                <option value="">Pilih Ruangan</option>
                {ruanganOptions.map((ruangan, index) => (
                  <option key={index} value={ruangan.Ruangan}>
                    {ruangan.Ruangan}
                  </option>
                ))}
              </select>

              <button className="modal-button edit" type="submit">
                {isUpdateMode ? "Update" : "Add"}
              </button>

              {isUpdateMode && (
                <button
                  className="modal-button delete"
                  type="button"
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className={`modal ${showConfirmationModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Update or Delete Asset</h2>
            <span className="close" onClick={closeConfirmationModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            {selectedAsset && (
              <form onSubmit={handleFormSubmitDelete}>
                <label>
                  Reason for deletion:
                  <select value={deleteReason} onChange={handleReasonChange}>
                    <option value="">Select reason</option>
                    <option value="Jual">Jual</option>
                    <option value="Lelang">Lelang</option>
                    <option value="Hilang">Hilang</option>
                  </select>
                </label>
                <button className="modal-button delete" type="submit">
                  Delete Asset
                </button>
                <button
                  className="modal-button edit"
                  type="button"
                  onClick={closeConfirmationModal}
                >
                  Cancel
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* Upload CSV Modal */}
      <div className={`modal ${showUploadModal ? "open" : ""}`}>
        <div
          className="modal-content settings-view"
          style={{ maxWidth: "768px", textAlign: "left" }}
        >
          <div className="modal-content-header">
            <h2>Upload CSV</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <div className="upload-csv-container">
              <div className="file-input-container">
                <div className="file-input">
                  <label htmlFor="csvFile">Upload CSV:</label>
                  <input
                    type="file"
                    id="csvFile"
                    name="csvFile"
                    accept=".csv"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                {renderCsvPreview()}
                <div className="file-buttons">
                  <button onClick={handleClearFile}>Clear</button>
                  <button onClick={handleFileUpload} disabled={!selectedFile}>
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DBFetcher;
