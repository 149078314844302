import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import './DBFetcher.css'; // Import the CSS file

const MaintenanceFetcher = ({
  onFetchTargetItems,
  currentItems = [],
  searchQuery = "",
  user,
}) => {
  const [changesHistory, setChangesHistory] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKondisi, setFilterKondisi] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const location = useLocation();

  useEffect(() => {
    fetchChangesHistory();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchTerm, filterKondisi, changesHistory]);

  useEffect(() => {
    handleSortAssets();
  }, [sortConfig]);

  const fetchChangesHistory = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/assets-maintenance");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setChangesHistory(data.assets);
      setFilteredAssets(data.assets);
      console.log("Changes History fetched:", data.assets);
    } catch (error) {
      console.error("Error fetching changes history:", error);
    }
  };

  const handleFilter = () => {
    let filteredData = changesHistory;

    if (searchTerm) {
      filteredData = filteredData.filter((asset) =>
        Object.values(asset).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (filterKondisi) {
      filteredData = filteredData.filter(
        (asset) => asset.kondisi === filterKondisi
      );
    }

    setFilteredAssets(filteredData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleSortAssets = () => {
    let sortedAssets = [...filteredAssets];

    if (sortConfig.key) {
      sortedAssets.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredAssets(sortedAssets);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterKondisi = (event) => {
    setFilterKondisi(event.target.value);
  };

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="table-container">
      <div className="search-filter-container">
        
        {location.pathname === "/scan-room" && (
          <div style={{ textAlign: "left" }}>
            <button className="modal-button edit" style={{ maxWidth: "120px" }}>
              Export Excel Dokumen Hilang
            </button>
            <button className="modal-button edit" style={{ maxWidth: "120px" }}>
              Export PDF Dokumen Hilang
            </button>
          </div>
        )}
      </div>

      <table className="asset-table">
        <thead>
          <tr id="tb-header" style={{backgroundColor:"#abcaf5"}}>
            <th onClick={() => handleSort("no_rfid")}>
              No RFID{" "}
              {sortConfig.key === "no_rfid" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("id_asset")}>
              ID Asset{" "}
              {sortConfig.key === "id_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("no_inventaris")}>
              No Inventaris{" "}
              {sortConfig.key === "no_inventaris" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("kode_asset")}>
              Kode Asset{" "}
              {sortConfig.key === "kode_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("nama_asset")}>
              Nama Asset{" "}
              {sortConfig.key === "nama_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("kondisi")}>
              Kondisi{" "}
              {sortConfig.key === "kondisi" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("umur_pakai")}>
              Umur Pakai{" "}
              {sortConfig.key === "umur_pakai" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("tanggal_perawatan")}>
              Tanggal Perawatan{" "}
              {sortConfig.key === "tanggal_perawatan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("nama_pic")}>
              Nama PIC{" "}
              {sortConfig.key === "nama_pic" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("status_asset")}>
              Status Asset{" "}
              {sortConfig.key === "status_asset" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("harga_perolehan")}>
              Harga Perolehan{" "}
              {sortConfig.key === "harga_perolehan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("no_contract")}>
              No Contract{" "}
              {sortConfig.key === "no_contract" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
            <th onClick={() => handleSort("keterangan")}>
              Keterangan{" "}
              {sortConfig.key === "keterangan" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.map((change, index) => (
            <tr key={index}>
              <td style={{ color: 'red' }}>{change.no_rfid}</td>
              <td style={{ color: 'red' }}>{change.id_asset}</td>
              <td style={{ color: 'red' }}>{change.no_inventaris}</td>
              <td style={{ color: 'red' }}>{change.kode_asset}</td>
              <td style={{ color: 'red' }}>{change.nama_asset}</td>
              <td style={{ color: 'red' }}>{change.kondisi}</td>
              <td style={{ color: 'red' }}>{formatDate2(change.umur_pakai)}</td>
              <td style={{ color: 'red' }}>{formatDate2(change.tanggal_perawatan)}</td>
              <td style={{ color: 'red' }}>{change.nama_pic}</td>
              <td style={{ color: 'red' }}>{change.status_asset}</td>
              <td style={{ color: 'red' }}>{change.harga_perolehan}</td>
              <td style={{ color: 'red' }}>{change.no_contract}</td>
              <td style={{ color: 'red' }}>{change.keterangan}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MaintenanceFetcher;
